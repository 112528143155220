import tw from "tailwind-styled-components";
import styled from "styled-components";

export const DropDownListItemWrapper = tw.div`
  flex
  flex-col
  gap-4
  border-2
  border-gray-200
  rounded-[20px]

  ${({ $isOpen }) =>
    $isOpen &&
    `
      bg-secondary-100
      border-cta-400
    `}
`;

export const HeaderWrapper = tw.div`
  flex
  justify-between
  items-center
  p-4 desktop:p-6
  cursor-pointer

  ${({ $isOpen }) =>
    $isOpen &&
    `
      border-b
      border-gray-200
      px-0 desktop:px-0
      mx-4 desktop:mx-6
      pb-4 desktop:pb-6
    `}
`;

export const IconTitle = tw.div`
  flex
  gap-4
  items-center

  [&>svg]:w-6
`;

export const IndexWrapper = styled.div`
  > svg {
    width: 24px;
    height: 24px;
  }

  @media screen and (min-width: 1128px) {
    > svg {
      width: 32px;
      height: 32px;
    }
  }
`;

export const Title = tw.span`
  text-lg desktop:text-xl
`;

export const NumberIndexWrapper = tw.div`
  text-2xl desktop:text-3xl
`;

export const BodyWrapper = styled.div`
  padding: 0 16px 16px 16px;

  p {
    font-size: 18px;
    line-height: 28px;
    margin: 0;
  }

  @media screen and (min-width: 1128px) {
    padding: 0 24px 24px 24px;

    p {
      font-size: 20px;
      line-height: 30px;
    }
  }
`;
