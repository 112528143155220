module.exports = {
  content: ["./src/**/*.{js,jsx,ts,tsx}"],
  theme: {
    screens: {
      desktop: "1128px",
    },
    borderWidth: {
      DEFAULT: "1px",
      0: "0",
      2: "2px",
      3: "3px",
      4: "4px",
      6: "6px",
      8: "8px",
    },
    extend: {
      colors: {
        "primary-400": "#256359",
        "primary-800": "#004039",
        "secondary-100": "#fff7ed",
        "secondary-200": "#fdf0e3",
        "cta-400": "#f88862",
        "cta-800": "#ea6d43",
        gray: "#333333",
        "gray-100": "#f5f5f5",
        "gray-200": "#e5e5e5",
        "gray-700": "#666666",
      },
      spacing: {
        "section-spacing-mobile": "40px",
        "section-spacing-desktop": "144px",
      },
    },
  },
  plugins: [],
};
