import { useState } from "react";
import resolveConfig from "tailwindcss/resolveConfig";
import tailwindConfig from "../../../../tailwind.config";
import { CheckmarkFilled, ChevronDown, ChevronUp , LocationFilled} from "@carbon/icons-react";
import {
  DropDownListItemWrapper,
  HeaderWrapper,
  IconTitle,
  IndexWrapper,
  Title,
  NumberIndexWrapper,
  BodyWrapper,
} from "./styles";
import EditorJSContent from "../../editor-js-content";

const NumberIndex = ({ index }) => {
  return <NumberIndexWrapper>{index}.</NumberIndexWrapper>;
};

const LIST_TYPE = {
  checklist: CheckmarkFilled,
  numberlist: NumberIndex,
  locationlist: LocationFilled
};

export const DropDownListItem = ({ index, title, body, type }) => {
  const { theme } = resolveConfig(tailwindConfig);
  const [isOpen, setIsOpen] = useState(false);
  const IconChevron = isOpen ? ChevronUp : ChevronDown;
  const Index = LIST_TYPE[type];
  const gray = theme.colors["gray"];
  const primary800 = theme.colors["primary-800"];
  const cta400 = theme.colors["cta-400"];

  return (
    <DropDownListItemWrapper $isOpen={isOpen}>
      <HeaderWrapper onClick={() => setIsOpen(!isOpen)} $isOpen={isOpen}>
        <IconTitle>
          <IndexWrapper>
            <Index color={primary800} index={index} />
          </IndexWrapper>
          {title && <Title>{title}</Title>}
        </IconTitle>
        <IndexWrapper>
          <IconChevron color={isOpen ? cta400 : gray} />
        </IndexWrapper>
      </HeaderWrapper>
      {isOpen && (
        <BodyWrapper>
          <EditorJSContent content={body} />
        </BodyWrapper>
      )}
    </DropDownListItemWrapper>
  );
};

export default DropDownListItem;
